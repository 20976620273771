import regeneratorRuntime from "/vercel/path0/projects/merchant/node_modules/next/node_modules/regenerator-runtime/runtime.js";
import { getSessionToken } from '@shopify/app-bridge-utils';
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
var parseJwt = function(token) {
    try {
        return JSON.parse(window.atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};
var getShopAndHost = _asyncToGenerator(regeneratorRuntime.mark(function _callee(app) {
    var sessionToken, shop, host;
    return regeneratorRuntime.wrap(function _callee$(_ctx) {
        while(1)switch(_ctx.prev = _ctx.next){
            case 0:
                _ctx.next = 2;
                return getSessionToken(app);
            case 2:
                sessionToken = _ctx.sent;
                shop = parseJwt(sessionToken).dest.replace(/https:\/\//, '');
                host = Buffer.from("".concat(shop, "/admin")).toString('base64');
                return _ctx.abrupt("return", {
                    shop: shop,
                    host: host
                });
            case 6:
            case "end":
                return _ctx.stop();
        }
    }, _callee);
}));
export default getShopAndHost;
