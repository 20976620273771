import regeneratorRuntime from "/vercel/path0/projects/merchant/node_modules/next/node_modules/regenerator-runtime/runtime.js";
import { useAppBridge } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { Modal, Redirect } from '@shopify/app-bridge/actions';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import sleep from '@common/utils/sleep';
import getShopAndHost from '@/utils/get-shop-and-host';
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
/**
 * session tokenを使ってバックエンドにHTTPリクエストを行う
 */ var useAuthAxios = function() {
    var t = useTranslation('common').t;
    var app = useAppBridge();
    var authAxios = axios.create({
        baseURL: process.env.AUTH_API_ENDPOINT,
        headers: {
            'Content-type': 'application/json'
        }
    });
    var router = useRouter();
    var messageModal = Modal.create(app, {
        message: t('セッションの有効期限が切れたため、再取得します。')
    });
    authAxios.interceptors.request.use(function(config) {
        return getSessionToken(app).then(function(token) {
            if (config.headers) {
                config.headers['Authorization'] = "Bearer ".concat(token);
            }
            return config;
        });
    });
    var redirect = Redirect.create(app);
    authAxios.interceptors.response.use(function(response) {
        return response;
    }, _asyncToGenerator(regeneratorRuntime.mark(function _callee(error) {
        var ref, shop, host;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    if (!(error.response.status === 403 || error.response.status === 401)) {
                        _ctx.next = 11;
                        break;
                    }
                    messageModal.dispatch(Modal.Action.OPEN);
                    _ctx.next = 4;
                    return sleep(500);
                case 4:
                    try {
                        sessionStorage.setItem('anygift_previous_pathname', router.pathname);
                    } catch (e) {
                        console.log('failed to access sessionStorage');
                    }
                    _ctx.next = 7;
                    return getShopAndHost(app);
                case 7:
                    ref = _ctx.sent;
                    shop = ref.shop;
                    host = ref.host;
                    redirect.dispatch(Redirect.Action.REMOTE, "".concat(process.env.AUTH_API_ENDPOINT, "/auth?shop=").concat(shop, "&host=").concat(host));
                case 11:
                    return _ctx.abrupt("return", Promise.reject(error));
                case 12:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    })));
    var isAxiosError = useCallback(function(e) {
        return axios.isAxiosError(e);
    }, []);
    var getAxiosErrorData = useCallback(function(e) {
        var ref;
        return (ref = e.response) === null || ref === void 0 ? void 0 : ref.data;
    }, []);
    return {
        authAxios: authAxios,
        isAxiosError: isAxiosError,
        getAxiosErrorData: getAxiosErrorData
    };
};
export default useAuthAxios;
