import regeneratorRuntime from "/vercel/path0/projects/merchant/node_modules/next/node_modules/regenerator-runtime/runtime.js";
import { useAppBridge } from '@shopify/app-bridge-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import getShopAndHost from '@/utils/get-shop-and-host';
import useInIframe from './use-in-iframe';
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
/**
 * 現在のshopドメインを取得する
 */ var useShop = function() {
    var ref = useState(null), shop1 = ref[0], setShop = ref[1];
    var isInIframe = useInIframe().isInIframe;
    var router = useRouter();
    var app = useAppBridge();
    useEffect(function() {
        var main = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
            var shop, shopAndHost, query;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        ;
                        if (!isInIframe) {
                            _ctx.next = 8;
                            break;
                        }
                        _ctx.next = 4;
                        return getShopAndHost(app);
                    case 4:
                        shopAndHost = _ctx.sent;
                        shop = shopAndHost.shop;
                        _ctx.next = 9;
                        break;
                    case 8:
                        {
                            query = router.query;
                            if (!query.shop) {
                                shop = null;
                            } else {
                                shop = query.shop;
                            }
                        }
                    case 9:
                        setShop(shop);
                    case 10:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        main();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isInIframe
    ]);
    return {
        shop: shop1
    };
};
export default useShop;
