import regeneratorRuntime from "/vercel/path0/projects/merchant/node_modules/next/node_modules/regenerator-runtime/runtime.js";
import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import useAuthAxios from '@/hooks/use-auth-axios';
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
/**
 * 課金プランの更新が必要かどうかを確認し、必要なら更新画面にリダイレクトする
 */ var useBillingUpdateStatus = function() {
    var app = useAppBridge();
    var authAxios = useAuthAxios().authAxios;
    var redirect = Redirect.create(app);
    var router = useRouter();
    var main = useCallback(_asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var ref, _data, shouldUpdateBillingPlan, confirmationUrl;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return authAxios.get('/subscriptions/billing_plan_update_status');
                case 2:
                    ref = _ctx.sent;
                    _data = ref.data;
                    shouldUpdateBillingPlan = _data.shouldUpdateBillingPlan;
                    confirmationUrl = _data.confirmationUrl;
                    if (shouldUpdateBillingPlan) {
                        _ctx.next = 8;
                        break;
                    }
                    return _ctx.abrupt("return");
                case 8:
                    try {
                        redirect.dispatch(Redirect.Action.REMOTE, confirmationUrl);
                    } catch (e) {
                        router.replace({
                            pathname: confirmationUrl
                        });
                    }
                case 9:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    })), [
        authAxios,
        redirect,
        router
    ]);
    useEffect(function() {
        main();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
export default useBillingUpdateStatus;
