import { useState, useEffect } from 'react';
/**
 * 現在アプリの管理画面に iframe で埋め込まれているかを返す
 */ var useInIframe = function() {
    var ref = useState(null), isInIframe = ref[0], setIsInIframe = ref[1];
    useEffect(function() {
        try {
            setIsInIframe(window.self !== window.top);
        } catch (e) {
            setIsInIframe(true);
        }
    }, []);
    return {
        isInIframe: isInIframe
    };
};
export default useInIframe;
